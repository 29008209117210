import { render, staticRenderFns } from "./TPOConfiguration.vue?vue&type=template&id=8f085208&scoped=true"
import script from "./TPOConfiguration.vue?vue&type=script&lang=js"
export * from "./TPOConfiguration.vue?vue&type=script&lang=js"
import style0 from "./TPOConfiguration.vue?vue&type=style&index=0&id=8f085208&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8f085208",
  null
  
)

export default component.exports